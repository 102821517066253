import { navigationConstants } from '../../constants/navigation.constants'
import { requestApi } from '../../services/api.service'
import { switchNavigation } from './navigationApiData.actions'
import { activityActions } from '../activity.actions'
import { downloadConstants } from '../../constants/download.constants'

function formatNavigationForAPI(navigation, type) {
    //format passengers into int
    navigation.nbDepartingPassengers = parseInt(
        navigation.nbDepartingPassengers,
    )

    navigation.stopOvers.forEach(stop => {
        stop.nbLeavingPassengers = parseInt(stop.nbLeavingPassengers)
        stop.nbBoardingPassengers = parseInt(stop.nbBoardingPassengers)
    })

    //format Addresses
    if (navigation.departureAddress !== null) {
        navigation.departureAddress.fullAddress =
            navigation.departureAddress.fullText
        delete navigation.departureAddress.fullText
    }

    navigation.stopOvers.forEach(stop => {
        stop.address.fullAddress = stop.address.fullText
        delete stop.address.fullText
        delete stop.timestamp
    })

    if (navigation.arrivalAddress !== null) {
        navigation.arrivalAddress.fullAddress =
            navigation.arrivalAddress.fullText
        delete navigation.arrivalAddress.fullText
    }

    navigation.workingEquipmentHours.forEach(
        equipment => (equipment.hours = parseInt(equipment.hours)),
    )

    navigation.fillingLevels.forEach(
        equipment => (equipment.level = parseInt(equipment.level)),
    )

    delete navigation.startTimestamp
    delete navigation.endTimestamp
    if (type === 'edit') {
        delete navigation.boat
        delete navigation.boatId
    }

    if (navigation.delimitedDate === null) {
        navigation.delimitedDate = {
            startDate: new Date(),
            endDate: null,
        }
    }

    return navigation
}

function formatNavigationForFront(navigation) {
    //format Addresses
    if (navigation.departureAddress !== null) {
        navigation.departureAddress.fullText =
            navigation.departureAddress.fullAddress
        delete navigation.departureAddress.fullAddress
    }

    navigation.stopOvers.forEach(stop => {
        stop.address.fullText = stop.address.fullAddress
        delete stop.address.fullAddress
    })

    if (navigation.arrivalAddress !== null) {
        navigation.arrivalAddress.fullText =
            navigation.arrivalAddress.fullAddress
        delete navigation.arrivalAddress.fullAddress
    }

    if (navigation.delimitedDate.startDate)
        navigation.delimitedDate.startDate = new Date(
            navigation.delimitedDate.startDate,
        )

    if (navigation.delimitedDate.endDate)
        navigation.delimitedDate.endDate = new Date(
            navigation.delimitedDate.endDate,
        )

    navigation.stopOvers.forEach(stop => {
        if (stop.delimitedDate.startDate)
            stop.delimitedDate.startDate = new Date(
                stop.delimitedDate.startDate,
            )

        if (stop.delimitedDate.endDate)
            stop.delimitedDate.endDate = new Date(stop.delimitedDate.endDate)
    })

    return navigation
}

/**
 * add a navigation to bdd and in the redux
 *
 * @function addNewNavigation
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {Object} navigation - navigation object.
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 22/04/2024 - 17:30
 * @author Samuel.C
 */
export const addNewNavigation = navigation => {
    return dispatch => {
        dispatch({ type: navigationConstants.REQUEST_NAVIGATIONS })

        const formattedNavigation = formatNavigationForAPI(navigation, 'add')
        const createNavigation = formattedNavigation =>
            requestApi('/navigations', 'POST', formattedNavigation).then(
                result => {
                    dispatch(activityActions.getNavigationActivity(result.id))

                    dispatch({
                        type: navigationConstants.POST_NEW_NAVIGATION,
                        navigation: formatNavigationForFront(result),
                    })
                },
                error => {
                    console.error(error)
                    dispatch({ type: navigationConstants.ERROR_REQUEST })
                },
            )

        if (navigation.isAisUsed) {
            requestApi(
                `/boats/ais-follow/${navigation.boat.id}`,
                'POST',
                {},
            ).then(
                _ => createNavigation(formattedNavigation),
                error => {
                    console.error(error)
                    dispatch({
                        type: navigationConstants.ERROR_REQUEST,
                    })
                },
            )
        } else {
            createNavigation(formattedNavigation)
        }
    }
}

/**
 * Get all navigations from a group
 *
 * @function getNavigationsFromGroup
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {Object} groupId - id of the group.
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 06/05/2024 - 15:27
 * @author Samuel.C
 */
export const getNavigationsFromGroup = groupId => {
    return dispatch => {
        dispatch({ type: navigationConstants.REQUEST_NAVIGATIONS })

        requestApi(`/navigations/group/${groupId}`, 'GET').then(
            result => {
                dispatch({
                    type: navigationConstants.GET_NAVIGATIONS_FROM_GROUP,
                    navigations: result.map(nav =>
                        formatNavigationForFront(nav),
                    ),
                })
            },
            error => {
                console.error(error)
                dispatch({ type: navigationConstants.ERROR_REQUEST })
            },
        )
    }
}

/**
 * Get all navigations link to a boat
 *
 * @function getNavigationsFromBoat
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {Object} boatId - id of the boat.
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 06/05/2024 - 15:37
 * @author Samuel.C
 */
export const getNavigationsFromBoat = boatId => {
    return dispatch => {
        dispatch({ type: navigationConstants.REQUEST_NAVIGATIONS })

        requestApi(`/navigations/boat/${boatId}`, 'GET').then(
            result => {
                dispatch({
                    type: navigationConstants.GET_NAVIGATIONS_FROM_BOAT,
                    navigations: result.map(nav =>
                        formatNavigationForFront(nav),
                    ),
                })
            },
            error => {
                console.error(error)
                dispatch({ type: navigationConstants.ERROR_REQUEST })
            },
        )
    }
}

/**
 * Get navigation by id
 *
 * @function getNavigationById
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {Object} navigationId - id of the boat.
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 03/07/2024 - 15:50
 * @author Samuel.C
 */
export const getNavigationById = navigationId => {
    return dispatch => {
        dispatch({ type: navigationConstants.REQUEST_NAVIGATIONS })

        requestApi(`/navigations/${navigationId}`, 'GET').then(
            result => {
                dispatch({
                    type: navigationConstants.GET_NAVIGATION_BY_ID,
                    navigation: formatNavigationForFront(result),
                })
            },
            error => {
                console.error(error)
                dispatch({ type: navigationConstants.ERROR_REQUEST })
            },
        )
    }
}

/**
 * edit navigation in bdd and in the redux
 *
 * @function editNavigation
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {Object} navigation - navigation object.
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 22/04/2024 - 17:30
 * @author Samuel.C
 */
export const editNavigation = navigation => {
    return dispatch => {
        dispatch({ type: navigationConstants.REQUEST_NAVIGATIONS })

        const id = navigation.id
        const formattedNavigation = formatNavigationForAPI(navigation, 'edit')

        requestApi(`/navigations/${id}`, 'PUT', formattedNavigation).then(
            result => {
                dispatch(activityActions.getNavigationActivity(navigation.id))
                dispatch({
                    type: navigationConstants.UPDATE_NAVIGATION,
                    navigation: formatNavigationForFront(result),
                })
                dispatch(switchNavigation(id))
            },
            error => {
                console.error(error)
                dispatch({ type: navigationConstants.ERROR_REQUEST })
            },
        )
    }
}

export const deleteNavigation = navigation => {
    return dispatch => {
        dispatch({ type: navigationConstants.REQUEST_NAVIGATIONS })

        const id = navigation.id

        requestApi(`/navigations/${id}`, 'DELETE').then(
            result => {
                dispatch({
                    type: navigationConstants.DELETE_NAVIGATION,
                    navigationId: id,
                })
            },
            error => {
                console.error(error)
                dispatch({ type: navigationConstants.ERROR_REQUEST })
            },
        )
    }
}

export const getLogbookPdf = (context = 'fr', navIds, dowloadError) => {
    return requestApi(
        `/navigations/export/${context.toLowerCase()}/pdf?ids=${navIds}`,
        `GET`,
    )
        .then(
            result => {
                if (!result || result.status === 404) {
                    alert(dowloadError)
                }
            },
            error => {
                console.error(error)
                return null
            },
        )
        .catch(error => console.error(error))
}

export const getLogbookExcel = (context = 'FR', navIds) => {
    return requestApi(
        `/navigations/export/${context.toUpperCase()}/excel?ids=${navIds}`,
        `POST`,
        null,
        false,
        false,
    )
        .then(
            result => {
                if (!result || result.status === 404) return null
                return result.blob()
            },
            error => {
                console.error(error)
                return null
            },
        )
        .then(blob => {
            if (blob) {
                const href = window.URL.createObjectURL(blob)
                const link = document.createElement(`a`)
                link.href = href
                link.setAttribute(`download`, `logbook.xlsx`)
                document.body.appendChild(link)
                link.click()
            } else {
            }
        })
        .catch(error => console.error(error))
}
