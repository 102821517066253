export default {
    actif: {
        FR: `Actif`,
        EN: `Active`,
    },
    attente: {
        FR: `En attente`,
        EN: `Waiting`,
    },
    role1: {
        FR: `Administrateur`,
        EN: `Administrator`,
    },
    role2: {
        FR: `Superviseur`,
        EN: `Supervisor`,
    },
    role3: {
        FR: `Capitaine`,
        EN: `Captain`,
    },
    role4: {
        FR: `Marin`,
        EN: `Sailor`,
    },
    role5: {
        FR: `Propriétaire`,
        EN: `Owner`,
    },
    role6: {
        FR: `Prestataire`,
        EN: `Provider`,
    },
    roleDesc0: {
        FR: `Niveau le plus élevé, possède tous les droits`,
        EN: `Highest level, has all rights`,
    },
    roleDesc1: {
        FR: `Possède tous les droits sauf celui d'ajouter de nouveaux utilisateurs`,
        EN: `Has all rights except the right to add new users`,
    },
    roleDesc2: {
        FR: `Possède tous les droits liés à la gestion d'un bateau de votre flotte`,
        EN: `Has all the rights related to the management of a boat in your fleet`,
    },
    roleDesc3: {
        FR: `Accès à toutes les fonctions de base nécessaires à un membre d'équipage`,
        EN: `Access to all basic functions needed by a crew member`,
    },
    roleDesc4: {
        FR: `Peut visualiser toutes les informations de votre flotte mais ne peut rien modifier`,
        EN: `Can view all the information of your fleet but cannot modify anything`,
    },
    roleDesc5: {
        FR: `Peut accèder aux tâches où il est notifié et changer les pièces liées`,
        EN: `Can access the task where it is notified and change the related parts`,
    },
    suspendu: {
        FR: `Suspendu`,
        EN: `Suspended`,
    },
    oui: {
        FR: `Oui`,
        EN: `Yes`,
    },
    non: {
        FR: `Non`,
        EN: `No`,
    },
    societe: {
        FR: `Société`,
        EN: `Company`,
    },
    adresse: {
        FR: `Adresse`,
        EN: `Address`,
    },
    SIRET: {
        FR: `SIRET (optionnel)`,
        EN: `SIRET (optionnal)`,
    },
    phone: {
        FR: `Téléphone`,
        EN: `Phone`,
    },
    nom: {
        FR: `Nom`,
        EN: `Last name`,
    },
    prenom: {
        FR: `Prénom`,
        EN: `First name`,
    },
    email: {
        FR: `Email`,
        EN: `Email`,
    },
    bateauAsso: {
        FR: `Bateau associé`,
        EN: `Boat linked`,
    },
    persoRole: {
        FR: `Personnaliser le rôle`,
        EN: `Customize the role`,
    },
    infP: {
        FR: `Informations personnelles`,
        EN: `Private informations`,
    },
    newU: {
        FR: `Cet utilisateur fait-il partie d'une entreprise ?`,
        EN: `Is the user part of a company ?`,
    },
    roleEtPerm: {
        FR: `Rôles & Permissions`,
        EN: `Roles & Rights`,
    },
    perso: {
        FR: `Personnalisation des permissions`,
        EN: `Access Customization`,
    },
    add: {
        FR: `Ajout`,
        EN: `Add`,
    },
    modif: {
        FR: `Modification`,
        EN: `Edit`,
    },
    supp: {
        FR: `Suppression`,
        EN: `Delete`,
    },
    read: {
        FR: `Lecture`,
        EN: `Read`,
    },
    acc1: {
        FR: `Accès à "Flotte & statistiques"`,
        EN: `Access to "Fleet & statistics"`,
    },
    acc2: {
        FR: `Accès à "Journal de bord"`,
        EN: `Access to "Logbook"`,
    },
    acc3: {
        FR: `Accès à "BoatOn Book`,
        EN: `Access to "BoatOn Book"`,
    },
    acc4: {
        FR: `Accès à "Calendrier"`,
        EN: `Access to "Agenda"`,
    },
    acc5: {
        FR: `Accès à "Travail et absences"`,
        EN: `Access to "Work and leaves"`,
    },
    acc6: {
        FR: `Accès à "Inventaire"`,
        EN: `Access to "Inventory"`,
    },
    acc7: {
        FR: `Accès à "Commandes"`,
        EN: `Access to "Orders"`,
    },
    acc8: {
        FR: `Accès à "Tâches"`,
        EN: `Access to "Tasks"`,
    },
    acc9: {
        FR: `Accès à "Budget"`,
        EN: `Access to "Budget"`,
    },
    acc10: {
        FR: `Accès à "Documents"`,
        EN: `Access to "Documents"`,
    },
    acc11: {
        FR: `Accès à "Bateau"`,
        EN: `Access to "Boat"`,
    },
    acc12: {
        FR: `Accès aux "Paramètres des utilisateurs"`,
        EN: `Access to "Users settings"`,
    },
    acc13: {
        FR: `Accès aux "Paramètres de travail et absences"`,
        EN: `Access to "Work and leave settings"`,
    },
    acc14: {
        FR: `Accès aux "Paramètres des congés payés"`,
        EN: `Access to "Paid leaves settings"`,
    },

    roleName: {
        FR: `Nom du rôle`,
        EN: `Role name`,
    },
    roleDescription: {
        FR: `Donnez un nom à ce nouveau rôle pour le retrouver plus facilement`,
        EN: `Give this new role a name to make it easier to find`,
    },
    save: {
        FR: 'Sauvegarder',
        EN: 'Save',
    },
    essentialInfos: {
        FR: 'Informations essentielles',
        EN: 'Essentials informations',
    },
    additionalInfos: {
        FR: 'Informations complémentaires',
        EN: 'Additionals informations',
    },
    birthday: {
        FR: 'Date de naissance',
        EN: 'Date of birth',
    },
    contractType: {
        FR: 'Type de contrat',
        EN: 'Type of contract',
    },
    additionalInformations: {
        FR: 'Informations complémentaires',
        EN: 'Additional informations',
    },
    enterDateInCompany: {
        FR: `Date d'entrée dans l'entreprise`,
        EN: 'Date of joining the company',
    },
    leavingDateInCompany: {
        FR: `Date de départ dans l'entreprise`,
        EN: 'Date of leaving the company',
    },
    idNumber: {
        FR: 'Numéro de matricule',
        EN: 'Id Number',
    },
    shoesSize: {
        FR: 'Pointure',
        EN: 'Shoes size',
    },
    topClothesSize: {
        FR: 'Taille de vêtement haut',
        EN: 'Top clothes size',
    },
    bottomClothesSize: {
        FR: 'Taille de pentalon',
        EN: 'Pants size',
    },
    glovesSize: {
        FR: 'Tailles de gants',
        EN: 'Gloves size',
    },
    formations: {
        FR: 'Formations',
        EN: 'Trainings',
    },
    trainingName: {
        FR: 'Nom de la formation',
        EN: 'Training name',
    },
    acquisitionDate: {
        FR: `Date d'obtention`,
        EN: 'Acquisition date',
    },
    expiryDate: {
        FR: `Date d'expiration`,
        EN: 'Expiry date',
    },
    addFormation: {
        FR: 'Ajouter une formation',
        EN: 'Add a training',
    },
    editFormation: {
        FR: `Edition d'une formation`,
        EN: 'Edit a training',
    },
    deleteConfirmTitle: {
        FR: `Confirmation de la suppression`,
        EN: 'Confirm deletion',
    },
    deleteConfirmMessage: {
        FR: `La suppression de la formation sera définitive.`,
        EN: `Withdrawal from the training will be definitive.`,
    },
}
