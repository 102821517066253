import { typesConstants } from '../constants'
import { typesService } from '../services/types.service'
import { requestApi } from '../services/api.service'

const requestBoatTypes = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_BOATTYPES,
        })
        return typesService.requestBoatTypes().then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_BOATTYPES_SUCCESS,
                    boatTypes: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_BOATTYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestOrderStatusType = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_ORDER_STATUS_TYPES,
        })
        return requestApi('/types/order-status', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_ORDER_STATUS_TYPES_SUCCESS,
                    orderStatusTypes: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_ORDER_STATUS_TYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestEventTypes = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_EVENTTYPES,
        })
        return requestApi('/event-types', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_EVENTTYPES_SUCCESS,
                    eventTypes: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_EVENTTYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestCheckupTypes = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_CHECKUPTYPES,
        })
        return requestApi('/checkups/checkupTypes', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_CHECKUPTYPES_SUCCESS,
                    checkupTypes: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_CHECKUPTYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestEquipmentFamilyTypes = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_EQUIPMENTFAMILYTYPES,
        })
        return requestApi('/equipment-family-types', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_EQUIPMENTFAMILYTYPES_SUCCESS,
                    equipmentFamilyTypes: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_EQUIPMENTFAMILYTYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestEquipmentTypes = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_EQUIPMENTTYPES,
        })
        return requestApi('/equipment-types', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_EQUIPMENTTYPES_SUCCESS,
                    equipmentTypes: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_EQUIPMENTTYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

async function promotionOfferValide(stripeId) {
    return requestApi('/types/offer/' + stripeId, 'GET').then(
        result => {
            return result
        },
        error => {
            console.error(error)
        },
    )
}

const requestPartTypes = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_PARTTYPES,
        })
        return requestApi('/part-types', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_PARTTYPES_SUCCESS,
                    partTypes: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_PARTTYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestUserCheckups = boatId => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_USERCHECKUPS,
        })
        return requestApi('/checkups/userCheckups/boat/' + boatId, 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_USERCHECKUPS_SUCCESS,
                    userCheckups: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_USERCHECKUPS_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestUserEquipmentFamilies = boatId => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_USEREQUIPMENTFAMILIES,
        })
        return requestApi(
            '/equipment-families/list/boat/' + boatId,
            'GET',
        ).then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_USEREQUIPMENTFAMILIES_SUCCESS,
                    userEquipmentFamilies: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_USEREQUIPMENTFAMILIES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestUserEquipments = boatId => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_USEREQUIPMENTS,
        })
        return requestApi('/equipments/list/boat/' + boatId, 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_USEREQUIPMENTS_SUCCESS,
                    userEquipments: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_USEREQUIPMENTS_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestUserParts = boatId => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_USERPARTS,
        })
        return requestApi('/parts/list/boat/' + boatId, 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_USERPARTS_SUCCESS,
                    userParts: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_USERPARTS_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestBlockTypes = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_BLOCKTYPES,
        })
        return requestApi('/types/blocks', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_BLOCKTYPES_SUCCESS,
                    blockTypes: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_BLOCKTYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const getEventTypeById = (eventTypes, id) => {
    const index = eventTypes.find(eventType => eventType.id === id)
    return index
}

const getTypeById = (types, id) => {
    const index = types.find(type => type.checkupType && type.id === id)
    return index
}

const getEventTypeDocument = eventTypes => {
    if (!eventTypes || !Array.isArray(eventTypes)) return null
    return eventTypes.filter(eventType => eventType.document)
}

const getEventTypeTransaction = eventTypes => {
    if (!eventTypes || !Array.isArray(eventTypes)) return null
    return eventTypes.filter(
        eventType => eventType.earning || eventType.spending,
    )
}

// TODO
// Actuellement le back ne renvoie pas la liste des différents statuts possible pour une absence
// (ex: "En attente", "Validée", "Refusée")
const getAbsenceStates = eventTypes => {
    if (!eventTypes || !Array.isArray(eventTypes)) return null
    return eventTypes.filter(eventType => eventType.workState)
}

const getEventTypeCheckup = eventTypes => {
    if (!eventTypes || !Array.isArray(eventTypes)) return null
    return eventTypes.filter(eventType => eventType.repair)
}

const requestLeaveTypes = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_LEAVE_TYPES,
        })
        return requestApi('/types/absence-reason', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_LEAVE_TYPES_SUCCESS,
                    leaveTypes: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_LEAVE_TYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestParkingOptionTypes = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_PARKING_TYPES,
        })
        return requestApi('/types/parking-option', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_PARKING_TYPES_SUCCESS,
                    parkingOptionTypes: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_PARKING_TYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestEngineTypeDetails = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_ENGINETYPEDETAILS,
        })
        return requestApi('/engine-type-details', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_ENGINETYPEDETAILS_SUCCESS,
                    engineTypeDetails: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_ENGINETYPEDETAILS_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestUserUse = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_USERUSER_TYPES,
        })
        return requestApi('/types/user-use', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_USERUSER_TYPES_SUCCESS,
                    userUses: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_USERUSER_TYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestFrequency = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_FREQUENCY_TYPES,
        })
        return requestApi('/types/frequency', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_FREQUENCY_TYPES_SUCCESS,
                    frequencies: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_FREQUENCY_TYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestPresence = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_PRESENCE_TYPES,
        })
        return requestApi('/types/presence', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_PRESENCE_TYPES_SUCCESS,
                    presences: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_PRESENCE_TYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestProBoatActivity = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_PROBOATACTIVITY_TYPES,
        })
        return requestApi('/types/pro-boat-activity', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_PROBOATACTIVITY_TYPES_SUCCESS,
                    proBoatActivities: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_PROBOATACTIVITY_TYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestPrivateBoatActivity = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_PRIVATEBOATACTIVITY_TYPES,
        })
        return requestApi('/types/private-boat-activity', 'GET').then(
            result => {
                dispatch({
                    type:
                        typesConstants.REQUEST_PRIVATEBOATACTIVITY_TYPES_SUCCESS,
                    privateBoatActivities: result,
                })
                return result
            },
            error => {
                dispatch({
                    type:
                        typesConstants.REQUEST_PRIVATEBOATACTIVITY_TYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestNavFrequency = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_NAVFREQUENCY_TYPES,
        })
        return requestApi('/types/nav-frequency', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_NAVFREQUENCY_TYPES_SUCCESS,
                    navFrequencies: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_NAVFREQUENCY_TYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestBoatLicence = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_BOATLICENCE_TYPES,
        })
        return requestApi('/types/boat-licence', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_BOATLICENCE_TYPES_SUCCESS,
                    boatLicences: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_BOATLICENCE_TYPES_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestContractTypes = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_CONTRACT_TYPES,
        })

        return requestApi('/types/contract', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_CONTRACT_TYPES_SUCCESS,
                    contractTypes: result,
                })
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_CONTRACT_TYPES_FAILURE,
                    error: error,
                })
            },
        )
    }
}

const requestTrainingTypes = () => {
    return dispatch => {
        dispatch({
            type: typesConstants.REQUEST_TRAINING_TYPES,
        })

        return requestApi('/trainings', 'GET').then(
            result => {
                dispatch({
                    type: typesConstants.REQUEST_TRAINING_TYPES_SUCCESS,
                    trainingTypes: result,
                })
            },
            error => {
                dispatch({
                    type: typesConstants.REQUEST_TRAINING_TYPES_FAILURE,
                    error: error,
                })
            },
        )
    }
}

export const typesActions = {
    requestBoatLicence,
    requestPrivateBoatActivity,
    requestNavFrequency,
    requestPresence,
    requestProBoatActivity,
    requestUserUse,
    requestFrequency,
    requestBoatTypes,
    requestEventTypes,
    getEventTypeById,
    getEventTypeTransaction,
    getAbsenceStates,
    getEventTypeDocument,
    getEventTypeCheckup,
    requestCheckupTypes,
    requestPartTypes,
    requestEquipmentFamilyTypes,
    requestEquipmentTypes,
    getTypeById,
    requestUserCheckups,
    requestUserEquipmentFamilies,
    requestUserEquipments,
    requestUserParts,
    promotionOfferValide,
    requestBlockTypes,
    requestParkingOptionTypes,
    requestEngineTypeDetails,
    requestLeaveTypes,
    requestOrderStatusType,
    requestContractTypes,
    requestTrainingTypes,
}
