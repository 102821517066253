import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
    removeFilesFromBOBEvent,
    setBOBStateEvent,
    deleteFileFromBobEvent,
    getImageToDisplay,
} from '../../../../actions/bob.actions'
import { Popover, Button as ButtonMui, IconButton } from '@material-ui/core'
import { filterActions } from '../../../../actions/filter.actions'
import styles from './Styles/MyDocumentListCss'
import dictionary from './Dictionary/MyDocumentListDico'
import Typography from '@material-ui/core/Typography'
import AttachFile from '@material-ui/icons/AttachFile'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import BoatOnModal from '../../../common/BoatOnModal'
import BoatOnImageRender from '../../../common/BoatOnImageRender'
import BoatOnModalCore from '../../../common/BoatOnModalCore'
import { typesActions } from '../../../../actions/types.actions'
import BobFilter from '../BobFilter'
import DocumentModal from './DocumentModal'
import BoatOnRenderDate from '../../../common/UsefullComponents/BoatOnRenderDate'
import {
    MoreHoriz as MoreHorizIcon,
    Cached as CachedIcon,
} from '@material-ui/icons'
import ModalNoBoat from '../../../common/UsefullComponents/ModalConnection/ModalNoBoat'
import BoatOnBlock from '../../../common/Blocks/BoatOnBlock'
import { getContextFromUrl } from '../../../../languages/LocalizerUtils'
import HourAlert from '../HourAlert'
import { trainingActions } from '../../../../actions/bob/trainings.actions'

class MyDocumentList extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            events: [],
            event: null,
            training: null,
            openModal: false,
            fileNoUrl: { event: [] },
            displayNewFiles: { event: [] },
            openDoc: false,
            deletedFiles: { event: [] },
            error: false,
            renderPopup: false,
            anchorPopup: null,
            documentPopup: null,
            openModalNoBoat: false,
        }

        this.handleClosePopup = this.handleClosePopup.bind(this)
        this._renderPopup = this._renderPopup.bind(this)
        this.handleOpenPopOverMore = this.handleOpenPopOverMore.bind(this)

        this.reset = this.reset.bind(this)
        this.handleOpenModalMyDocument = this.handleOpenModalMyDocument.bind(
            this,
        )
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleClosePopup() {
        this.setState({
            renderPopup: false,
            anchorPopup: null,
            documentPopup: null,
        })
    }

    async handleClose() {
        this.setState({ openModalNoBoat: false })
    }

    _renderPopup() {
        const { classes, documents = [] } = this.props
        const { documentPopup } = this.state

        return (
            <Popover
                id={'popover'}
                open={this.state.renderPopup}
                anchorEl={this.state.anchorPopup}
                onClose={this.handleClosePopup}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.iconsMobile}>
                    <BoatOnBlock
                        blockIds={4}
                        children={
                            <ButtonMui
                                onClick={() => {
                                    this.handleClosePopup()
                                    this.edit(documentPopup)
                                }}
                                className={classes.actionMobile}
                                startIcon={<Edit />}
                            >
                                {this.displayText(`edit`)}
                            </ButtonMui>
                        }
                    />

                    {documentPopup.files && documentPopup.files.length > 0 && (
                        <ButtonMui
                            onClick={() => {
                                this.handleClosePopup()
                                this.handleOpenModalMyDocument(documentPopup)
                            }}
                            className={classes.actionMobile}
                            startIcon={<AttachFile />}
                        >
                            {this.displayText(`files`)}
                        </ButtonMui>
                    )}

                    <BoatOnBlock
                        blockIds={6}
                        children={
                            <ButtonMui
                                onClick={() => {
                                    if (
                                        window.confirm(
                                            this.displayText('validateDelete'),
                                        )
                                    ) {
                                        this.handleClosePopup()
                                        this.delete(documentPopup)
                                    }
                                }}
                                className={classes.actionMobile}
                                startIcon={<Delete />}
                            >
                                {this.displayText(`supprimerDoc`)}
                            </ButtonMui>
                        }
                    />
                </div>
            </Popover>
        )
    }

    handleOpenPopOverMore(e, event) {
        this.setState({
            documentPopup: event,
            renderPopup: true,
            anchorPopup: e.currentTarget,
        })
    }

    componentDidUpdate(prevProps) {
        const {
            dispatch,
            events,
            eventTypes,
            loadingEvents,
            loadingTrainings,
            bobFilter,
            groupLoading,
            trainings,
            currentUser,
        } = this.props

        if (
            (events &&
                Array.isArray(events) &&
                eventTypes &&
                loadingEvents === 0 &&
                prevProps.loadingEvents !== 0) ||
            JSON.stringify(prevProps.bobFilter) !== JSON.stringify(bobFilter)
        ) {
            const documents = []
            events.forEach(event => {
                const eventType = typesActions.getEventTypeById(
                    eventTypes,
                    event.eventType.id,
                )
                if (
                    event.files &&
                    eventType &&
                    event.files.length > 0 &&
                    eventType.document
                ) {
                    documents.push(event)
                }
            })
            this.setState({ events: documents })
        }

        if (loadingTrainings === 0 && prevProps.loadingTrainings !== 0) {
            this.reset()
        }

        if (
            !trainings &&
            loadingTrainings === 0 &&
            groupLoading === 0 &&
            currentUser?.id
        )
            dispatch(trainingActions.getAllTraining(currentUser.id))
    }

    componentDidMount() {
        const { dispatch, events, eventTypes, groupId } = this.props

        if (events && eventTypes && Array.isArray(events)) {
            const documents = []
            events.forEach(event => {
                const eventType = typesActions.getEventTypeById(
                    eventTypes,
                    event.eventType.id,
                )
                if (
                    event.files &&
                    eventType &&
                    event.files.length > 0 &&
                    eventType.document
                ) {
                    documents.push(event)
                }
            })
            this.setState({ events: documents })
        }

        if (groupId) dispatch(trainingActions.getAllTrainingByGroup(groupId))
    }

    handleOpenModalMyDocument(document) {
        this.setState({ openModal: 'showDocs', openDoc: document })
    }

    reset() {
        this.setState({
            openModal: false,
            event: null,
            training: null,
            fileNoUrl: { event: [] },
            displayNewFiles: { event: [] },
            deletedFiles: { event: [] },
            openDoc: false,
            error: false,
        })
    }

    handleOpenModal() {
        if (this.props.boat) {
            this.setState({ openModal: 'add', openDoc: false })
            setBOBStateEvent(this)
        } else {
            this.setState({ openModalNoBoat: true })
        }
    }

    deleteDocumentNoUp(i) {
        deleteFileFromBobEvent(this, i, {
            deletedFiles: this.state.deletedFiles.event,
            displayNewFiles: this.state.displayNewFiles.event,
            fileNoUrl: this.state.fileNoUrl.event,
            files: this.state.event.files,
        })
    }

    renderDocuments() {
        const { openDoc } = this.state

        return (
            <BoatOnModalCore
                body={
                    <BoatOnImageRender
                        vertical
                        multi
                        bottomImage
                        imgs={getImageToDisplay(
                            openDoc,
                            this.state.displayNewFiles.event,
                        )}
                    />
                }
            />
        )
    }

    edit(document) {
        const { eventTypes } = this.props
        const eventType = typesActions.getEventTypeById(
            eventTypes,
            document.eventType?.id,
        )

        if (eventType) {
            this.setState({
                event: document,
                openModal: 'add',
                edit: true,
            })
        } else {
            const trainingType = typesActions.getEventTypeById(eventTypes, 22)

            this.setState({
                event: {
                    eventType: trainingType,
                },
                training: document,
                openModal: 'add',
                edit: true,
            })
        }
    }

    delete(document) {
        const { dispatch, eventTypes } = this.props
        const eventType = typesActions.getEventTypeById(
            eventTypes,
            document.eventType?.id,
        )
        if (eventType) dispatch(removeFilesFromBOBEvent(document.id))
        else dispatch(trainingActions.deleteTraining(document.id))
    }

    _getDetailAndChekupTitle(event) {
        const { checkup, detail } = event
        if (checkup)
            return checkup?.checkupType
                ? this.displayTextApi(checkup.checkupType.translation)
                : checkup?.userCheckup
                ? checkup.userCheckup.name
                : event.title
        else if (detail) {
            const part = detail.part
            const equipment = detail.equipment
            if (part) {
                if (part.userPart) return part.userPart.name
                else if (part.partType)
                    return this.displayTextApi(part.partType.translation)
            } else if (equipment) {
                if (equipment.userEquipment) {
                    return equipment.userEquipment.name
                } else if (equipment.equipmentType)
                    return this.displayTextApi(
                        equipment.equipmentType.translation,
                    )
            }
        }
        return event.title
    }

    _setTitle(event) {
        if (!event) return ''
        if (event.checkup || event.detail)
            return `${this.displayText(
                'editDocument',
            )} - ${this._getDetailAndChekupTitle(event)}`
        return this.displayText('editDocument')
    }

    isPassedDate(date) {
        const today = new Date()

        date = new Date(date)

        return today > date
    }

    isNearDate(date) {
        let today = new Date()

        date = new Date(date)

        date = new Date(date.setMonth(date.getMonth() - 3))

        return today > date
    }

    _renderLine(document, index) {
        const { classes, eventTypes } = this.props

        let eventType = typesActions.getEventTypeById(
            eventTypes,
            document.eventType?.id,
        )

        if (!eventType)
            eventType = typesActions.getEventTypeById(eventTypes, 22)

        return (
            <div
                key={index}
                className={`${classes.normalLine} line`}
                style={
                    document?.delimitedDate?.endDate
                        ? {
                              backgroundColor: this.isPassedDate(
                                  document.delimitedDate.endDate,
                              )
                                  ? index % 2
                                      ? '#E36B611A'
                                      : '#E36B6126'
                                  : this.isNearDate(
                                        document.delimitedDate.endDate,
                                    )
                                  ? index % 2
                                      ? '#F4BA5C1A'
                                      : '#F4BA5C26'
                                  : undefined,
                          }
                        : undefined
                }
            >
                <div className={classes.dates}>
                    <div className={classes.datesDayMonth}>
                        <BoatOnRenderDate
                            date={new Date(document?.delimitedDate?.startDate)}
                        />
                    </div>
                </div>
                <div className={classes.containerText}>
                    <div className={classes.row}>
                        <img
                            src={
                                eventType.photo
                                    ? require(`images/EventBobIcon/${eventType.photo}`)
                                    : ''
                            }
                            alt={this.displayTextApi(eventType.translation)}
                            className={classes.icon}
                        />
                        <div className={classes.titleAndType}>
                            <Typography
                                className={classes.subtitle}
                                variant="body1"
                            >
                                {this._getDetailAndChekupTitle(document)}
                                {document.training &&
                                    this.displayTextApi(
                                        document.training.translation,
                                    )}
                                {document.training &&
                                    document.user &&
                                    ` - ${document.user.firstName} ${document.user.lastName}`}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.type}
                            >
                                {this.displayTextApi(eventType.translation)}
                            </Typography>
                        </div>
                    </div>
                    {document?.delimitedDate?.endDate && (
                        <div className={classes.expiryDate}>
                            <div className={classes.expiryDateTitle}>
                                {this.displayText('expiryDateTitle')}
                            </div>
                            <div
                                className={classes.expiryDateData}
                                style={{
                                    color: this.isPassedDate(
                                        document.delimitedDate.endDate,
                                    )
                                        ? '#E36B61'
                                        : this.isNearDate(
                                              document.delimitedDate.endDate,
                                          )
                                        ? '#F4BA5C'
                                        : 'black',
                                }}
                            >
                                {this.isPassedDate(
                                    document.delimitedDate.endDate,
                                ) ? (
                                    <CachedIcon
                                        style={{
                                            backgroundColor: '#E36B61',
                                        }}
                                        className={classes.expiryDateIcon}
                                    />
                                ) : this.isNearDate(
                                      document.delimitedDate.endDate,
                                  ) ? (
                                    <CachedIcon
                                        className={classes.expiryDateIcon}
                                    />
                                ) : null}
                                {new Date(
                                    document.delimitedDate.endDate,
                                ).toLocaleDateString('fr-FR')}
                            </div>
                        </div>
                    )}
                    <IconButton
                        className={`${classes.iconButton}`}
                        onClick={e => {
                            this.handleOpenPopOverMore(e, document)
                        }}
                    >
                        <MoreHorizIcon style={{ color: `black` }} />
                    </IconButton>
                </div>
            </div>
        )
    }

    render() {
        const {
            classes,
            loadingEvents,
            loadingTrainings,
            eventTypes,
            boat,
            trainings,
            currentUser,
        } = this.props
        const { openModal, events, event, training } = this.state
        if (loadingEvents || !eventTypes || loadingTrainings) return <></>

        const documents = [...events, ...(trainings || [])].sort(
            (a, b) =>
                new Date(b.delimitedDate.startDate) -
                new Date(a.delimitedDate.startDate),
        )

        return (
            <div className={classes.root}>
                {/* test blocs roles */}
                <Typography className={classes.title}>
                    {this.displayText('documents')} - {boat.name}
                </Typography>
                <div className={classes.firstLine}>
                    <BobFilter
                        fieldName={'events'}
                        editableButton
                        editableButtonBlockIds={2}
                        editableButtonOnClick={this.handleOpenModal}
                        editableButtonLabel={this.displayText('youAddDocument')}
                        activeButton={{ rangeDate: true }}
                    />
                </div>
                <HourAlert />
                <div className={classes.infos}>
                    {!documents || documents.length === 0 ? (
                        <Typography className={classes.noDoc}>
                            {this.displayText('noDocument')}
                        </Typography>
                    ) : (
                        documents.map((document, index) =>
                            this._renderLine(document, index),
                        )
                    )}
                </div>
                <BoatOnModal
                    openCondition={openModal}
                    handleClose={this.reset}
                    modalCores={{
                        add: (
                            <DocumentModal
                                eventTypes={eventTypes}
                                event={event}
                                training={training}
                                noCross={false}
                                initialUser={currentUser}
                            />
                        ),
                        showDocs: this.renderDocuments(),
                    }}
                    maxWidth={{
                        add: 'sm',
                    }}
                    titles={{
                        add:
                            event?.id || training?.id
                                ? this._setTitle(event ? event : training)
                                : this.displayText('youAddDocument'),
                        showDocs: this.displayText('documents'),
                    }}
                />
                <ModalNoBoat
                    open={this.state.openModalNoBoat}
                    handleClose={() => {
                        this.handleClose()
                    }}
                />
                {this.state.renderPopup && this._renderPopup()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const context = getContextFromUrl(url)

    const currentUser = (state.group?.groupsMembers?.linkRGU || []).find(
        userLinked =>
            userLinked.user?.email === state.authentication?.user?.email,
    )

    return {
        user: state.authentication.user,
        currentUser,
        boats: state.fetch.boats,
        documents: state.bob.documents,
        spendings: state.bob.spendings,
        bob: state.bob,
        boat: state.bob.boat,
        events: filterActions.filterBobState(
            state.bob.events,
            state.filter.bobFilter,
            context,
            {
                searchString: true,
                rangeDate: true,
                checkupTypesChecked: false,
            },
        ),
        loadingEvents: state.bob.loading,
        loadingTrainings: state.trainings.loading,
        bobFilter: state.filter.bobFilter,
        eventTypes: typesActions.getEventTypeDocument(state.types.eventTypes),
        groupLoading: state.group.loading,
        groupId: state.group.currentGroupId,
        trainings: state.trainings?.trainings,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(MyDocumentList))
